<template>
  <div class="home">
    <v-container>
      <v-btn v-if="isAdminCheck" @click="runTask()">Run Task</v-btn>
      <!-- center the below h1 in the middle of the page-->
      <div class="container">
        <h1 class="centered-h1">
          THE DIGITAL INCEPTION PLAN WEB APPLICATION IS DOWN FOR MAINTENANCE,
          APOLOGIES FOR THE INCONVENIENCE
        </h1>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    isAdminCheck() {
      if (
        this.$msal.getUser().username === "Mitchell.Harvey@arup.com" ||
        this.$msal.getUser().username === "Luca.Lucioli@arup.com"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async runTask() {
      let successfulUpdates = 0;
      let errors = 0;
      let plansToBeUpdated = 0;
      console.log("running task...");
      const response = await this.$dip.get(
        `${this.$store.state.ApiUri}/GetResults`
      );
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].JobCode) {
        } else {
          plansToBeUpdated += 1;
        }
      }
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].JobCode) {
        } else {
          console.log("no jobcode for" + ` ${plansToBeUpdated} plans`);

          // call fullUpdateDIP here
          await this.$dip
            .post(
              `${this.$store.state.ApiUri}/UpdateJobCodeResult`,
              response.data[i]
            )
            .then((res) => {
              successfulUpdates += 1;
              console.log("response data", res.data);
            })
            .catch((err) => {
              errors += 1;
              console.log(err.response);
            });
        }
      }
      console.log(
        "plans to be updated: ",
        plansToBeUpdated + "/" + response.data.length
      );
      console.log(
        "successful updates: ",
        successfulUpdates + "/" + plansToBeUpdated
      );
      console.log("errors: ", errors);
      if (
        successfulUpdates / plansToBeUpdated === 1 ||
        plansToBeUpdated === 0
      ) {
        if (plansToBeUpdated === 0) {
          console.log("no plans to update");
        } else {
          console.log("all plans updated successfully");
        }
      } else {
        console.log("some plans failed to update");
        console.log("run this task again to update the remaining plans");
      }
    },
  },
  mounted() {
    if (!this.$store.state.maintenanceMode === "true") {
      this.$router.push({ name: `Home` });
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the container to the full viewport height */
}

.centered-h1 {
  text-align: center;
}
</style>
